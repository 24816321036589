import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import CareerService from '../service/CareerService';
import { useNavigate } from 'react-router-dom';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';

const PlanUnilevel = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [current, setCurrent] = useState('');
    const [percent, setPercent] = useState([]);
    const [currentPercent, setCurrentPercent] = useState('');
    const careerService = new CareerService();

    useEffect(() => {
        careerService.getPercentPlan().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setPlans(data.percent);
            setCurrent(data.current);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (plans) => {
        const borderStyle = current >= plans.system_level_id ? '3px solid rgb(105, 183, 255)' : '3px solid grey';
        return (
            <div className="col-12 sm:col-6 lg:col-8 md:col-8 xl:col-4 p-2">
                <div className="p-4 surface-card border-round" style={{border:borderStyle}}>
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <div className="flex align-items-center gap-2">
                            <span className="text-2xl font-semibold">Target : $ {plans.required_volume}</span>
                        </div>
                        {(current >= plans.system_level_id) ? 
                        <Tag value="Reached" severity="warning"></Tag>
                        : <Tag value={'Not reached'} style = {{backgroundColor:'grey'}}></Tag>
                        }
                        
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <div className="text-2xl font-bold">{plans.bonus} %</div>
                    </div>
                    <div className="flex align-items-center gap-2">
                        <span className="text-2xl font-semibold">{plans.level_name}</span>
                    </div>
                </div>
            </div>
        );
    };
    
    return (
        <div className="card">
                <DataView value={plans} itemTemplate={itemTemplate} />
        </div>
    );
};

export default PlanUnilevel;