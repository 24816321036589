import React, { useRef,useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import DashboardService from '../service/DashboardService';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Dashboard = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [amount, setAmount] = useState({});
    const [login, setLogin] = useState({});
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(1);
    const [lastLogin, setlastLogin] = useState(null);
    const [lastTsNews, setlastTsNews] = useState(null);
    const [rate, setRate] = useState({});
    const [members, setMembers] = useState([]);
    const [news_list, setNews_list] = useState([]);
    const [career, setCareer] = useState([]);
    const [tokenStaked, setTokenStaked] = useState(0);
    const [secondsDifference, setSecondsDifference] = useState(0);
    const [tokenStakeRoiToday, setTokenStakeRoiToday] = useState(0);
    const [tokenStakeRoiPaid, setTokenStakeRoiPaid] = useState(0);
    const [tokenWallet, setTokenWallet] = useState(0);
    const [incrementValue, setIncrementValue] = useState(0);
    const [isNewsReaded, setIsNewsReaded] = useState(1);
    const [isTicketReaded, setIsTicketReaded] = useState(1);
    const [roiSecond, setRoiSecond] = useState(0);
    const [incentive, setIncentive] = useState([]);
    const [careerName, setCareerName] = useState('');
    const [careerGift, setCareerGift] = useState(0);
    const [visibleCareer, setVisibleCareer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [differenceInDays, setDifferenceInDays] = useState(null);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setVisibleCareer(false)} icon="pi pi-check" className="p-button-secondary" />;
    const basicDialogFooterNews = <Button type="button" label="Open the page" onClick={() => navigate('/news')} icon="pi pi-check" className="p-button-primary" />;
    const dashboardService = new DashboardService();
    const registerLink = `https://app.meloads.io/register/${login}`;
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        dashboardService.getDepositPending().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
        });
        dashboardService.getAmount().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setAmount(data);
            setTokenStakeRoiToday(parseFloat(data.roi_today))
            setTokenStakeRoiPaid(parseFloat(data.roi_paid))
            setTokenStaked(parseFloat(data.package_amount_all_token));
            setSecondsDifference(parseFloat(data.seconds_difference));
            setTokenWallet(parseFloat(data.token_wallet_live_amount));
            setRoiSecond(parseFloat(data.roi_second));
            if (data.package_amount_active > 0)
            {
                setIncrementValue(parseFloat(data.token_wallet_live_amount) + parseFloat(data.roi_paid));
            }
            else
            {
                setIncrementValue(parseFloat(data.token_wallet_live_amount));
            }
            setLogin(data.login);
            const totalInvest = parseFloat(data.package_amount_all) + parseFloat(data.pack_amount);
            const totalIncome = parseFloat(data.total_income);
            if(totalInvest > 1)
            {
                const x4 = Math.round(totalInvest * 4);
                const Pie1 = x4 - totalIncome;
                if(totalIncome < x4)
                {
                    setTo(Pie1);
                    setFrom(totalIncome);
                }
            }
        });
        dashboardService.getNews().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setIsNewsReaded(data.is_news_readed);
            setIsTicketReaded(data.is_ticket_readed);
        });
        dashboardService.getCareer().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setCareer(data);
        });
        dashboardService.checkCareerReach().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "success")
            {
                setCareerName(res.data.name);
                setCareerGift(res.data.gift);
                setVisibleCareer(true);
            }
        });
        dashboardService.getChart().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setRate(data);
        });
        dashboardService.initToken().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
        });
        dashboardService.checkIncentive().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCopyLink = () => {
        navigator.clipboard.writeText(registerLink)
          .then(() => {
            setCopySuccess(true)
            toast.current.show({ severity: "success", summary: "Success", detail: "Link copied", life: 3000 });
          })
          .catch(() => setCopySuccess(false));
    };

    const pieOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#ffffff'
                }
            }
        }
    };

    const lineOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#ffffff'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            },
            y: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            }
        }
    };

    const barOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#ffffff'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            },
            y: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            }
        }
    };

    const getChartPie = () => {
        return {
                labels: ['Total earn', 'x4'],
                datasets: [
                    {
                        data: [from,to],
                        backgroundColor: ['#5729D6', '#DF86AA']
                    }
                ]
        };
    };

    const getChartLine = () => {
        return {
                labels: rate.ts,
                datasets: [
                    {
                        label: 'Daily rate',
                        data: rate.rate,
                        fill: false,
                        backgroundColor: '#5729D6',
                        borderColor: '#5729D6',
                        tension: 0.4
                    }
                ]
        };
    };

    const getChartBar = () => {
        return {
                labels: rate.token_ts,
                datasets: [
                    {
                        label: 'Rewards',
                        backgroundColor: '#DF86AA',
                        borderColor: '#DF86AA',
                        data: rate.token
                    }
                ]
        };
    };

    const chartData = getChartPie();
    const chartDataLine = getChartLine();
    const chartDataBar = getChartBar();

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12">
                    <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card h-full flex align-items-center justify-content-start p-3">
                        <div className="flex flex-column">
                            <h5>Dear Members,<br/>

We have some bad news to share with you.<br/>

Three weeks ago, one of my private accounts was hacked, leading to the loss of several tens of millions on a DeFi protocol. A portion of this money was being used to stabilize the token price at 0.10 and to work on the app launch.<br/>

Since this incident, we have tried everything to find a solution to continue MeloAds' operations.<br/>

However, we realize that priority must be given to the investors who believed in this project.<br/>

Unfortunately, with each passing day, the remaining funds are dwindling. Therefore, we have decided to pause our operations and reimburse all members who have invested in our project. This pause will allow us to take a step back and gain clearer perspectives.<br/>

Starting tomorrow, or within a maximum of 48 hours, we will begin reimbursing 50% of the deposits made by each member. We also need to inform you that any profits earned will be deducted from the amounts to be reimbursed. We are doing our best to, in the near future, redistribute a portion of the remaining funds and fully reimburse the investors.<br/>

We wanted to inform you first, and we hope you understand. Please share this message with all your teams.<br/>

As soon as the site is accessible, you will be able to make withdrawals.<br/>

Best regards,<br/>

Mohombi Moupondo<br/>
CEO</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-6">
                <Toast ref={toast} />
                    <div className="card h-full flex align-items-center justify-content-start p-3">
                        <div className="flex flex-column">
                            <h6>
                                {amount.package_amount_all > 0 ? 
                                (
                                    <>
                                        You can share this link for subscriptions in your network :{' '}https://app.meloads.io/register/{amount.login}
                                        <button className="p-link" onClick={handleCopyLink}>
                                            <i  style={{fontSize:'1.5em',color:'#ffffff'}} className="ml-2 fas fa-copy"></i>
                                        </button>
                                    </>
                                ):
                                    <>
                                        To activate your referral link, you have to invest
                                    </>
                                }
                                {amount.is_gain_blocked == 1 ? 
                                (
                                    <>
                                        <p style={{color:'red'}}>Your account is not active, you have to invest</p>
                                    </>
                                ):
                                    <></>
                                }
                                
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-3 xl:col-3">
                    <div className="card h-full flex align-items-center justify-content-start p-3" onClick={()=> navigate('/contact')}>
                        <div className="flex flex-column">
                            <h6>
                                You have {isTicketReaded == 0 ? (<><span>1 </span> </>):<>0 </>}unread ticket message {isTicketReaded == 0 ? (<><i style={{color:'#DF86AA',fontSize:'1.5em',marginLeft:'5px'}} className="fas fa-envelope fa-beat"></i></>):<></>}
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-3 xl:col-3">
                    <div className="card h-full flex align-items-center justify-content-start p-3" onClick={()=> navigate('/news')}>
                        <div className="flex flex-column">
                            <h6>
                                You have {isNewsReaded == 0 ? (<><span className=''>1 </span></>):<>0 </>} unread news {isNewsReaded == 0 ? (<><i style={{color:'#5729D6',fontSize:'1.5em',marginLeft:'5px'}} className="fas fa-envelope fa-beat"></i></>):<></>}
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12 xl:col-12 pb-0">
                    <div className="grid">
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-wallet"></i>
                                <div className="overview-info text-right">
                                    <h5>My wallet</h5>
                                    <h4>$ {parseFloat(amount.total_wallet)}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-chart-simple"></i>
                                <div className="overview-info text-right">
                                    <h5>Total staked</h5>
                                    <h4>{amount.package_amount_all_token && parseFloat(amount.package_amount_all_token)} MAP</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-globe"></i>
                                <div className="overview-info text-right">
                                    <h5>Total volume</h5>
                                    <h4>{amount.total_volume_token && amount.total_volume_token} MAP</h4>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="far fa-user"></i>
                                <div className="overview-info text-right">
                                    <h5>Direct members</h5>
                                    <h4>{amount.total_direct && amount.total_direct}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-chart-line"></i>
                                <div className="overview-info text-right">
                                    <h5>Rewards</h5>
                                    <h4>{incrementValue && incrementValue.toFixed(8)} MAP</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-dollar-sign"></i>
                                <div className="overview-info text-right">
                                    <h5>Network income</h5>
                                    <h4>$ {amount.networking_income && amount.networking_income}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-user-tie"></i>
                                <div className="overview-info text-right">
                                    <h5>Your rank</h5>
                                    <h4>{amount.name && amount.name}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{color:'white',background:'linear-gradient(to left top, #DF86AA, #5729D6)'}} className="card overview-box white">
                                <i style={{fontSize:'3em'}} className="fas fa-cubes-stacked"></i>
                                <div className="overview-info text-right">
                                    <h5>My network</h5>
                                    <h4>{amount.tree_member && amount.tree_member}</h4>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-12 xl:col-6">
                    <div className="card">
                        <h5>STAKING PROGRESS</h5>
                        <Chart type="line" data={chartDataLine} options={lineOptions}></Chart>
                    </div>
                </div>
                <div className="col-12 xl:col-6">
                    <div className="card">
                        <h5>DAILY EARNING</h5>
                        <Chart type="bar" data={chartDataBar} options={barOptions}></Chart>
                    </div>
                </div>
                <div className="col-12 md:col-12 xl:col-12">
                    <div className="grid">
                        <div className="col-12 md:col-3 xl:col-3">
                            <div style={{height:'485px'}} className="card chart">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Earning limit</h6>
                                    </div>
                                </div>
                                <Chart type="pie" data={chartData} options={pieOptions} style={{ width: '100%' }} />
                            </div>
                        </div>
                        <div className="col-12 md:col-9 xl:col-9">
                            <div className="grid">
                                <div className="col-12">
                                    <div className="card stocks">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h6>Next rank</h6>
                                                <p className="subtitle">{career.next_career_name && career.next_career_name}</p>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="grid">
                                                <div className="col-12 md:col-12 xl:col-3">
                                                    <b>Strong leg target ($ {career.current_strong_branch && career.current_strong_branch} / $ {career.next_career_strong_branch && career.next_career_strong_branch} )</b>
                                                </div>
                                                <div className="col-12 md:col-12 xl:col-9">
                                                    <ProgressBar className="progressBar" value={career.next_career_strong_branch && Math.round((career.current_strong_branch/career.next_career_strong_branch)*100)} showValue={false}></ProgressBar>
                                                </div>
                                                <div className="col-12 md:col-12 xl:col-3">
                                                    <b>Other legs target ($ {career.current_sum_low_branch && career.current_sum_low_branch} / $ {career.next_career_sum_low_branch && career.next_career_sum_low_branch} )</b>
                                                </div>
                                                <div className="col-12 md:col-12 xl:col-9">
                                                    <ProgressBar className="progressBar" value={career.next_career_sum_low_branch && Math.round((career.current_sum_low_branch/career.next_career_sum_low_branch)*100)} showValue={false}></ProgressBar>
                                                </div>
                                                <div className="col-12 md:col-12 xl:col-3">
                                                    <b>Staking target ($ {career.total_current_package && career.total_current_package} / $ {career.next_package && career.next_package} )</b>
                                                </div>
                                                <div className="col-12 md:col-12 xl:col-9">
                                                    <ProgressBar className="progressBar" value={career.next_package && Math.round((career.total_current_package/career.next_package)*100)} showValue={false}></ProgressBar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Dashboard;
