import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Expiration = () => {
const navigate = useNavigate();
localStorage.removeItem('token');
console.clear();
  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <button onClick={() => navigate('/')} className="logo p-link">
            <img src="assets/layout/images/logo_meloads.png" alt="Meloads logo" />
            <span style={{color:'#5729D6',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Meloads</span>
          </button>
            <h3 style={{color:'#5729D6',fontFamily:'arial',fontWeight:'bold'}}>Your token has been expired please sign in again</h3>
            <Button label="Go to login" onClick={() => navigate('/login')}></Button>
        </div>
        <div className="login-footer">
          <h5>Copyright Meloads</h5>
        </div>
      </div>
    </div>
  );
};
