import React, { useRef,useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AxiosService from './../service/AxiosService';
import { Toast } from 'primereact/toast';
export const Confirmation = () => {
const navigate = useNavigate();
const toast = useRef();
const url =  new AxiosService();
const apiUrl = url.apiUrl;
const params = useParams();
const { token } = params;
useEffect(() => {
    if(token.length === 0)
    {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'This url is expired', life: 3000 });
    }
    else
    {
        axios.post(apiUrl + '/auth/confirmation', {"token":token})
        .then((response) => {
        if (response.data.status.type === "success")
        {
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.status.message, life: 3000 });
        }
        else
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
        }
        })
        .catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
        });
    }
}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
            <Toast ref={toast} />
          <button onClick={() => navigate('/')} className="logo p-link">
            <img src="assets/layout/images/logo_meloads.png" alt="meloads logo" />
            <span style={{color:'orange',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Meloads</span>
          </button>
            <h3 style={{color:'orange',fontFamily:'arial',fontWeight:'bold'}}>Your email is confirmed</h3>
            <Button label="Go to login" onClick={() => navigate('/login')}></Button>
        </div>
        <div className="login-footer">
          <h5>Copyright Meloads</h5>
        </div>
      </div>
    </div>
  );
};
