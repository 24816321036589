import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightPanel from './AppRightPanel';

import Dashboard from './components/Dashboard';
import AutoInvest from './components/AutoInvest';
import MyTeam from './components/MyTeam';
import Contact from './components/Contact';
import Deposit from './components/Deposit';
import Faq from './components/Faq';
import Investing from './components/Investing';
import News from './components/News';
import Plan from './components/Plan';
import PlanUnilevel from './components/PlanUnilevel';
import Setting from './components/Setting';
import SumTransaction from './components/SumTransaction';
import SumInvesting from './components/SumInvesting';
import SumNetwork from './components/SumNetwork';
import SumRoi from './components/SumRoi';
import SumMyteam from './components/SumMyteam';
import SumTransfer from './components/SumTransfer';
import SumResidual from './components/SumResidual';
import SumTeamBonus from './components/SumTeamBonus';
import Transfer from './components/Transfer';
import Withdrawal from './components/Withdrawal';
import WithdrawalToken from './components/WithdrawalToken';
import { NotFound } from './pages/NotFound';
import EmptyPage from './pages/EmptyPage';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = (props) => {
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(true);
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [colorScheme, setColorScheme] = useState('light');
    const [topbarScheme, setTopbarScheme] = useState('dark');
    const [menuScheme, setMenuScheme] = useState('dark');
    const [themeScheme, setThemeScheme] = useState('dark');
    const [theme, setTheme] = useState('purple');
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const menu = [
        {
            label: 'Dashboard',
            icon: 'fas fa-chart-line',
            to: '/'
        },
        {
            label: 'My wallet',
            icon: 'fas fa-suitcase',
            items: [
                { label: 'Deposit', icon: 'fas fa-plus', to: '/deposit' },
                { label: 'Withdrawal USD', icon: 'fas fa-minus', to: '/withdrawal' },
                { label: 'Withdrawal MAP', icon: 'fas fa-minus', to: '/withdrawaltoken' },
                { label: 'Transfer', icon: 'fas fa-users', to: '/transfer' },
            ]
        },
        {
            label: 'Staking',
            icon: 'fa-solid fa-rocket',
            to: '/investing'
        },
        {
            label: 'My network',
            icon: 'fas fa-cubes-stacked',
            to: '/myteam'
        },
        {
            label: 'Summary',
            icon: 'fas fa-table-list',
            items: [
                { label: 'Transactions', icon: 'fas fa-list', to: '/sumtransaction' },
                { label: 'Staking', icon: 'fas fa-list', to: '/suminvesting' },
                { label: 'Networking', icon: 'fas fa-list', to: '/sumnetwork' },
                { label: 'Team Bonus', icon: 'fas fa-list', to: '/sumTeamBonus' },
                { label: 'Roi', icon: 'fas fa-list', to: '/sumroi' },
                { label: 'My team', icon: 'fas fa-list', to: '/summyteam' },
                { label: 'Transfer', icon: 'fas fa-list', to: '/sumtransfer' }
            ]
        },
        {
            label: 'Team incomes',
            icon: 'fa fa-user-graduate',
            to: '/plan'
        },
        {
            label: 'Level incomes',
            icon: 'fas fa-user-tie',
            to: '/planunilevel'
        },
        {
            label: 'FAQ',
            icon: 'fas fa-signs-post',
            to: '/faq'
        },
        {
            label: 'Contact us',
            icon: 'fas fa-headset',
            to: '/contact'
        },
        {
            label: 'News',
            icon: 'fas fa-newspaper',
            to: '/news'
        },
        {
            label: 'Settings',
            icon: 'pi pi-cog',
            to: '/setting'
        },
        {
            label: 'Logout',
            icon: 'fas fa-arrow-right-from-bracket',
            to: '/logoff'
        }
    ];

    let menuClick;
    let rightPanelClick;
    let configClick;
    let searchClick;
    let topbarUserMenuClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        } else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true);
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false);
        }
        menuClick = true;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        if (menuMode === 'sidebar') {
            if (sidebarStatic) {
                setSidebarActive(true);
            }
        } else {
            setSidebarActive(false);
            if (topbarScheme !== menuScheme) {
                setMenuScheme(topbarScheme);
            }
        }
        if (topbarScheme === 'dark') {
            setThemeScheme('dark');
        }
    };

    const onColorSchemeChange = (scheme) => {
        setColorScheme(scheme);
        props.setColorScheme(scheme);
    };

    const onThemeSchemeChange = (scheme) => {
        setThemeScheme(scheme);
        setMenuScheme(scheme);
        setTopbarScheme(scheme);
    };

    const onTopbarSchemeChange = (scheme) => {
        setTopbarScheme(scheme);
    };

    const onMenuSchemeChange = (scheme) => {
        setMenuScheme(scheme);
    };

    const onThemeChange = (themeColor) => {
        setTheme(themeColor);
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setRightPanelActive(false);

        if (isMobile()) {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const onRightPanelClick = () => {
        rightPanelClick = true;
    };

    const onRightPanelButtonClick = () => {
        setRightPanelActive((prevState) => !prevState);
        rightPanelClick = true;
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const onTopbarSearchToggle = () => {
        setSearchActive((prevState) => !prevState);
        searchClick = true;
    };

    const onTopbarSearchClick = () => {
        searchClick = true;
    };

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive((prevState) => !prevState);
        topbarUserMenuClick = true;
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false);
            searchClick = false;
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false);
            topbarUserMenuClick = false;
        }

        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false);
        }

        if (!configClick && configActive) {
            setConfigActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true);
                setMenuActive(false);
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        searchClick = false;
        topbarUserMenuClick = false;
        rightPanelClick = false;
        configClick = false;
        menuClick = false;
    };

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };

    const onSidebarMouseLeave = () => {
        setSidebarActive(false);
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarStatic((prevState) => !prevState);

        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-sidebar': menuMode === 'sidebar',
            'layout-static': menuMode === 'sidebar' && sidebarStatic,
            'layout-horizontal': menuMode === 'horizontal',
            'layout-rightpanel-active': rightPanelActive,
            'layout-slim': menuMode === 'slim',
            'layout-mobile-active': staticMenuMobileActive,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        'layout-menu-dark layout-topbar-dark'
    );

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
            />

            <AppRightPanel onRightPanelClick={onRightPanelClick} />

            <div className="layout-main">
                <div className="layout-content">
                    <Routes>
                        <Route path="/" exact="true" element={<Dashboard />} />
                        <Route path="/dashboard" exact="true" element={<Dashboard />} />
                        <Route path="/autoinvest" exact="true" element={<AutoInvest />} />
                        <Route path="/myteam" exact="true" element={<MyTeam />} />
                        <Route path="/contact" exact="true" element={<Contact />} />
                        <Route path="/deposit" exact="true" element={<Deposit />} />
                        <Route path="/faq" exact="true" element={<Faq />} />
                        <Route path="/investing" exact="true" element={<Investing />} />
                        <Route path="/news" exact="true" element={<News />} />
                        <Route path="/plan" exact="true" element={<Plan />} />
                        <Route path="/planunilevel" exact="true" element={<PlanUnilevel />} />
                        <Route path="/setting" exact="true" element={<Setting />} />
                        <Route path="/suminvesting" exact="true" element={<SumInvesting />} />
                        <Route path="/sumnetwork" exact="true" element={<SumNetwork />} />
                        <Route path="/sumteambonus" exact="true" element={<SumTeamBonus />} />
                        <Route path="/sumtransaction" exact="true" element={<SumTransaction />} />
                        <Route path="/sumtransfer" exact="true" element={<SumTransfer />} />
                        <Route path="/sumroi" exact="true" element={<SumRoi />} />
                        <Route path="/summyteam" exact="true" element={<SumMyteam />} />
                        <Route path="/transfer" exact="true" element={<Transfer />} />
                        <Route path="/sumresidual" exact="true" element={<SumResidual />} />
                        <Route path="/withdrawal" exact="true" element={<Withdrawal />} />
                        <Route path="/withdrawaltoken" exact="true" element={<WithdrawalToken />} />
                        <Route path="/pages/empty" element={<EmptyPage />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    );
};

export default App;
