import React, { useState, useEffect,useRef } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PackageLayout from './layout/PackageLayout';
import PackageService from '../service/PackageService';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import WalletService from '../service/WalletService';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const Investing = () => {
    const navigate = useNavigate();
    const [adsPackages, setAdsPackages] = useState([]);
    const packageService = new PackageService();
    const toast = useRef();
    const [external_wallet, setExternal_wallet] = useState(0.00);
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [inputAmount, setInputAmount] = useState("");
    const [inputAmountMap, setInputAmountMap] = useState("");
    const [inputTotal, setInputTotal] = useState(0);
    const [fees, setFees] = useState(10);
    const [hash, setHash] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="Close" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
        </>
    );
    const walletService = new WalletService();
    useEffect(() => {
        walletService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setExternal_wallet(data.external_live_amount);
            setInternal_wallet(data.internal_live_amount);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeAmount = (e) => {
        if(e.target.value === null || e.target.value === NaN || e.target.value === 0 || e.target.value === '')
        {
            setInputAmountMap("0.00");
            setInputTotal("0.00");
        }
        else
        {
            setInputAmountMap(parseFloat(e.target.value) * 10);
            setInputTotal((parseFloat(e.target.value) * 10) - fees);
        }
        setInputAmount(e.target.value);
    };

    const buyMap = () => {
        setIsLoading(true);
        packageService.buyPackage(inputAmount).then((res) => {
            if(res.status.type === 'error')
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
                setIsLoading(false);
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                setIsLoading(false);
                setHash(res.data);
                setDisplayConfirmation(true);
                walletService.getLiveWalletData().then((data) => {
                    if(data === 401)
                    {
                        navigate('/expiration');
                    }
                    setExternal_wallet(data.external_live_amount);
                    setInternal_wallet(data.internal_live_amount);
                });
            }
        });
    };
    return (
        
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
        <Toast ref={toast} />
        <div className="mb-3 font-bold text-3xl">
            {/* <span className="text-blue-600">Increase your daily earning</span> */}
        </div>
        <div className="p-3 shadow-2 mb-3 inline-block surface-card mb-6" style={{ borderRadius: '10px' }}>
            <i className={"fas fa-wallet text-4xl text-primary"} ></i> <span className="text-900 text-xl mb-3 font-medium">Available amount {parseFloat(external_wallet) + parseFloat(internal_wallet)} $</span>
        </div>
        <div className="flex align-items-center justify-content-center">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <img src="assets/layout/images/logo_meloads_top2.png" alt="hyper" height={50} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">MAP TOKEN EXCHANGE</div>
                    <span className="text-600 font-medium line-height-3">Enter the amount (usd) you want to exchange to MAP token and stake it</span>
                    <p className="font-medium no-underline ml-2 text-blue-500">1 MAP = 0.1 USD</p>
                </div>
        
                <div>
                    <div className="p-inputgroup">
                        <Button label="USD" className='font-bold'/>
                        <InputText type="number" step="0.01" placeholder="0.00" className='text-4xl' value={inputAmount} onChange={handleChangeAmount}/>
                    </div>
                    <i style={{fontSize:'3em'}} className="fas fa-arrow-down mt-3"></i>
                    <div className="p-inputgroup mt-5">
                        <Button label="MAP" className='font-bold'/>
                        <InputText placeholder="0.00" className='text-4xl' value={inputAmountMap} disabled/>
                    </div>

                    <div className="surface-0 mt-3">
                        <div className="font-medium text-3xl text-900 mb-3">MAP TOKEN STAKING</div>
                        <div className="text-500 mb-5">Please read staking details before submit</div>
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-full md:w-2 font-medium text-left">PERIOD</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
                                <div className="w-12 md:w-2 flex justify-content-end">365 DAYS</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-full md:w-2 font-medium text-left">FEES</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
                                <div className="w-12 md:w-2 flex justify-content-end">{fees} MAP</div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-full md:w-2 font-medium text-left">TOTAL AFTER FEES</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1"></div>
                                <div className="w-12 md:w-2 flex justify-content-end">{inputTotal} MAP</div>
                            </li>
                        </ul>
                    </div>
                    <Button label="Start staking" icon="fas fa-line-chart" className="w-full mt-5 text-2xl" disabled={isLoading} onClick={buyMap}>
                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                    </Button>
                    <Dialog header="Stake information" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                        <div className="flex flex-column align-items-center justify-content-center">
                            <i className="pi pi-check mr-3" style={{ fontSize: '2rem' }} />
                            <h3>Congratulation!</h3>
                            <p><b>MAP Token : </b> {inputTotal}</p>
                            <span><b>Hash : </b> <a href={"https://bscscan.com/tx/" + hash} target="_blank" className=''>Click here</a></span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    </div>
    
    );
};

export default Investing;
