import React, { useRef,useState,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import countryList from './../assets/data/countries.json';
import axios from 'axios';
import AxiosService from './../service/AxiosService';
import { Toast } from 'primereact/toast';
import ReactRecaptcha3 from 'react-google-recaptcha3';

export const Register = () => {
  const toast = useRef();
    const navigate = useNavigate();
    const url =  new AxiosService();
    const apiUrl = url.apiUrl;
    const dropdownValues = countryList.data;
    const params = useParams();
    const { member } = params;
    const [inputData, setInputData] = useState({
      sponsor: member,
      login: '',
      password: '',
      password_repeat: '',
      email: '',
      email_repeat: '',
      name: '',
      firstname: '',
      country: '',
      terms: false,
      token:''
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if(member.length === 0)
      {
        navigate('/login');
      }
      else
      {
          ReactRecaptcha3.init('6LcutWIpAAAAAP5J-M9eK82sHhhlqzA5TJpHVw4g').then(
            (status) => {
              console.log(status);
            }
          );
          axios.post(apiUrl + '/auth/register', {"sponsor":member})
          .then((response) => {
          if (response.data.status.type === "error")
          {
            navigate('/login');
          }
          if (response.data.status.type === "maintenance")
          {
            navigate('/maintenance');
          }
          })
          .catch(() => {
            navigate('/login');
          });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [inputData]);
  
    const handleChange = (e) => {
      if(e.checked)
      {
        setInputData({ ...inputData, [e.target.id]: e.checked });
      }
      setInputData({ ...inputData, [e.target.id]: e.target.value });
    };

    const getToken = async () => {
      try {
        const res = await ReactRecaptcha3.getToken();
        return res;
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support.', life: 3000 });
      }
    }
  
    const handleSubmit = async () => {
      setIsLoading(true);
        if(inputData.password !== inputData.password_repeat)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Password and password repeat not match', life: 3000 });
        }
        else if(/[&'"<>]/.test(inputData.password))
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Your password contains not allowed characters', life: 3000 });
        }
        else if(!/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(inputData.password))
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Your password must contain at least 8 characters with minimum one uppercase and one number', life: 3000 });
        }
        else if(inputData.email !== inputData.email_repeat)
        {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Email and email repeat not match', life: 3000 });
        }
        else
        {
            const res = await getToken();
            axios.post(apiUrl + '/auth/register/new', { ...inputData, "token": res })
            .then((response) => {
              if (response.data.status.type === "error")
              {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
              }
              else if (response.data.status.type === "maintenance")
              {
                navigate('/maintenance');
              }
              else
              {
                ReactRecaptcha3.destroy();
                navigate('/welcome');
              }
            })
            .catch(() => {
              toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
            })
            .finally(() =>{setIsLoading(false);});
        }
      setIsLoading(false);
    };

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                <Toast ref={toast} />
                    <button onClick={() => navigate('/')} className="logo p-link">
                        <img src="/assets/layout/images/logo_meloads_top2.png" alt="Meloads logo" />
                        <span style={{color:'#5729D6',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Meloads</span>
                    </button>

                    <InputText id="login" keyfilter="alphanum" value={inputData.login} onChange={handleChange} placeholder="Login" required/>
                    <InputText id="email" type="email" value={inputData.email} onChange={handleChange} placeholder="Email" required/>
                    <InputText id="email_repeat" type="email" value={inputData.email_repeat} onChange={handleChange} placeholder="Repeat your email" required/>
                    <InputText id="password" type="password" value={inputData.password} onChange={handleChange} placeholder="Password" required/>
                    <InputText id="password_repeat" type="password" value={inputData.password_repeat} onChange={handleChange} placeholder="Repeat your password" required/>
                    <Dropdown id="country" value={inputData.country} onChange={handleChange} options={dropdownValues} optionValue="code" optionLabel="name" placeholder="Country" required></Dropdown>
                    <Button label="Register" onClick={() => handleSubmit(true)} disabled={isLoading} className='mt-2'>
                      {isLoading && <i className="pi pi-spin pi-spinner" />}
                    </Button>
                </div>
                <div className="login-footer">
                    <h6>All right reserved to Meloads Ⓒ 2024</h6>
                </div>
            </div>
        </div>
    );
};
