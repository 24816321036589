import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const AppFooter = () => {
    const navigate = useNavigate();

    return (
        <div className="layout-footer">
            <div className="grid">
                <div className="col-12">
                    <div className="footer-bottom">
                        <h4>Meloads</h4>
                        <h6>Copyright Ⓒ 2024</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
