import React, { useState, useEffect } from 'react';
import FaqService from '../service/FaqService';
import { useNavigate } from 'react-router-dom';

const Faq = () => {
    
    const navigate = useNavigate();
    const [faqs, setfaqs] = useState([]);
    const faqsService = new FaqService();

    useEffect(() => {
        faqsService.getFaqList().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setfaqs(data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="grid">
            <div className="col">                
                {faqs && faqs.map((faqList, index) => (
                    <div key={index} style={{borderLeft:'5px solid #5729D6'}} className="card p-fluid">
                        <h5>{faqList.title}</h5>
                        <div dangerouslySetInnerHTML={{ __html: faqList.description }}></div>
                    </div>
                ))}
                    
            </div>
        </div>
    );
};

export default Faq;