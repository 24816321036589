import axios from 'axios';

export default class AxiosService {
  constructor() {
    this.apiUrl = 'https://backend-service.meloads.io';
    this.apiContentType = 'application/json';
  }

  getAxiosInstanceJwt() {
      const axiosInstance = axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Content-Type': this.apiContentType,
          },
        });
        
      axiosInstance.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );
      return axiosInstance;
  }
  getAxiosInstance() {
    const axiosInstance = axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Content-Type': this.apiContentType,
        },
      });
    return axiosInstance;
  }
}